<template>
  <div>
    <!-- Formulär Container -->
    <div class="form-container sign-up-container">
      <form @submit.prevent="submitForm">
        <h1>Skapa konto</h1>
        
        
        <!-- Namnfält -->
        <memlist-text-input
          id="name"
          name="name"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('COMMON.NAME')"
          v-model="local_form.name"
          :placeholder="$t('COMMON.NAME')"
          :required="true"
          :invalid_text="$t('PUBLIC.FORM.INVALID_NAME')"
          @validated="validated"
        ></memlist-text-input>
        
        <!-- Organisationsnummer -->
        <memlist-text-input
          id="org_number"
          name="org_number"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.FORM.ORG_NUMBER')"
          v-model="local_form.org_number"
          :placeholder="$t('PUBLIC.FORM.ORG_NUMBER_PLACEHOLDER')"
          :required="true"
          :invalid_text="$t('PUBLIC.FORM.INVALID_ORG_NUMBER')"
          @validated="validated"
        ></memlist-text-input>
        
        <!-- Admin E-post -->
        <memlist-text-input
          id="admin_email"
          name="admin_email"
          validation_type="EMAIL"
          layout="vertical"
          :title="$t('MEMBER.EMAIL')"
          v-model="local_form.admin_email"
          :placeholder="$t('MEMBER.EMAIL_PLACEHOLDER')"
          :required="true"
          :invalid_text="$t('PUBLIC.FORM.INVALID_EMAIL')"
          @validated="validated"
        ></memlist-text-input>
        
        <!-- Admin Telefon -->
        <memlist-text-input
          id="admin_phone"
          name="admin_phone"
          validation_type="PHONE"
          layout="vertical"
          :title="$t('MEMBER.PHONE')"
          v-model="local_form.admin_phone"
          :placeholder="$t('MEMBER.PHONE_PLACEHOLDER')"
          :required="false"
          :invalid_text="$t('PUBLIC.FORM.INVALID_PHONE')"
          @validated="validated"
        ></memlist-text-input>
        
        <!-- Felmeddelanden -->
        <div v-if="signupError" class="error-message" role="alert">
          {{ signupError }}
        </div>
        <div v-if="signupSuccess" class="success-message" role="status">
          {{ signupSuccess }}
        </div>
        
        <!-- Submit Knapp -->
        <button type="submit" :disabled="isSubmitting">
          {{ isSubmitting ? $t('COMMON.CONTINUE') : $t('COMMON.CONTINUE') }}
        </button>
      </form>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'TestRegisterDonationCustomer',
  components: {
    
  },
  mixins: [ toasts ],
  data() {
    return {
      local_form: {
        name: '',
        org_number: '',
        admin_email: '',
        admin_phone: '',
        login_email: '',
        login_password: ''
      },
      signupError: '',
      signupSuccess: '',
      loginError: '',
      loginSuccess: '',
      isSubmitting: false
    };
  },
  methods: {
    validated(valid) {
      // Hantera valideringsresultat om det behövs
      // Exempel: console.log('Validated:', valid);
    },
    async submitForm() {
      this.signupError = '';
      this.signupSuccess = '';
      
      // Kontrollera obligatoriska fält
      if (!this.local_form.name || !this.local_form.org_number || !this.local_form.admin_email) {
        this.signupError = this.$t('PUBLIC.FORM.REQUIRED_FIELDS');
        return;
      }

      this.isSubmitting = true;
      
      try {
        const response = await axios.post('/customer/donation', {
          name: this.local_form.name,
          org_number: this.local_form.org_number,
          admin_email: this.local_form.admin_email,
          admin_phone: this.local_form.admin_phone
        });
        
        if (response.status === 201 || response.status === 200) {
          this.signupSuccess = this.$t('PUBLIC.FORM.REGISTER_SUCCESS');
          // Eventuellt rensa formuläret
          this.local_form.name = '';
          this.local_form.org_number = '';
          this.local_form.admin_email = '';
          this.local_form.admin_phone = '';
        } else {
          this.signupError = this.$t('PUBLIC.FORM.REGISTER_FAILED');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.signupError = error.response.data.message;
        } else {
          this.signupError = this.$t('PUBLIC.FORM.REGISTER_FAILED');
        }
      } finally {
        this.isSubmitting = false;
      }
    },
    async submitLogin() {
      this.loginError = '';
      this.loginSuccess = '';

      // Kontrollera obligatoriska fält
      if (!this.local_form.login_email || !this.local_form.login_password) {
        this.loginError = this.$t('PUBLIC.FORM.REQUIRED_FIELDS');
        return;
      }

      this.isSubmitting = true;

      try {
        const response = await axios.post('/api/login', {
          email: this.local_form.login_email,
          password: this.local_form.login_password
        });

        if (response.status === 200) {
          this.loginSuccess = this.$t('PUBLIC.FORM.LOGIN_SUCCESS');
          // Eventuellt omdirigera användaren
          this.$router.push('/dashboard'); // Justera sökvägen efter behov
        } else {
          this.loginError = this.$t('PUBLIC.FORM.LOGIN_FAILED');
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.loginError = error.response.data.message;
        } else {
          this.loginError = this.$t('PUBLIC.FORM.LOGIN_FAILED');
        }
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>


<style lang="css">
/* main.css */

/* Importera Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/* Globala stilar */
* {
  box-sizing: border-box;
}

body {
  background: #f6f5f7;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/* Top Header för Prisinformation */
.top-header {
  background-color: #4CAF50; /* Ljusa grön färg */
  padding: 15px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-nav {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_logo {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
}

.nav_items {
  list-style: none;
  display: flex;
  gap: 30px;
}

.nav_item {
  /* Flex item */
}

.nav_link {
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
}

.nav_link:hover {
  color: #d9d9d9;
}

.nav_buttons {
  display: flex;
  gap: 10px;
}

.nav_buttons .button {
  padding: 8px 20px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  transition: background 0.3s, transform 0.2s;
}

.nav_buttons .button:hover {
  background: #fff;
  color: #4CAF50;
}

.nav_buttons .button:active {
  transform: scale(0.98);
}

/* Huvudsektion */
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 20px 20px 20px; /* Top padding för att kompensera toppheadern */
  gap: 20px;
  width: 100%;
}

.home h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Formulär Container */
.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
              0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}


.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  width: 800px;
  margin: auto;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }
  
  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container{
  transform: translateX(-100%);
}

.overlay {
  background: #ff41c5;
  background: -webkit-linear-gradient(to right, #ffaa9b, #ff41c5);
  background: linear-gradient(to right, #ffaa9b, #ff41c5);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;

  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  color: #555;
  transition: color 0.3s, background 0.3s;
}

.social-container a:hover {
  color: #fff;
  background-color: #7d2ae8;
}

/* Formulär Panel */
.form-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-container input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 4px;
}

.form-container button[type="submit"] {
  margin-top: 10px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.form-container button[type="submit"]:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Footer */
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

/* Responsiv design */
@media (max-width: 768px) {
    .top-nav {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .nav_items {
        flex-direction: column;
        width: 100%;
    }

    .nav_items .nav_item {
        width: 100%;
    }

    .nav_buttons {
        flex-direction: column;
        width: 100%;
    }

    .nav_buttons .button {
        width: 100%;
        text-align: center;
    }

    .overlay-container {
        display: none; /* Dölj overlay på mindre skärmar */
    }

    .container {
        width: 100%;
        min-height: auto;
    }

    .home {
        padding: 80px 10px 20px 10px; /* Justera padding för mindre skärmar */
    }

    .overlay {
        display: none;
    }
}

</style>